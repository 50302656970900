
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import notification from '../components/notification.vue';
import { NotificationClient } from '@/services/Services';
import router from '@/router';
import { UrlServices } from '@/services/UrlServices';
import { notificationClicked } from '@/services/Firebase/FirebasePushServices';
import store from '@/store';
import { WSSServices } from '@/services/WSSServices';

@Options({
    components: {
        notification
    }
})
export default class Notifications extends Vue {

    notifications: OM.NotificationVM[] = [];

    created(){
        this.init()
    }

    onAffinityCreated(){
        document.addEventListener("resume", this.resumeCallback, false);
        document.addEventListener("pause", this.pauseCallback, false);
    }

    resumeCallback(){
        this.init();
    }

    pauseCallback(){
        WSSServices.disconnectFromNotificationSections(this.onNotificationReceivedInNotificationsSection);
    }

    init(){
        WSSServices.connectToNotificationSections(this.onNotificationReceivedInNotificationsSection);

        store.state.notificationNumber = 0;
        Promise.all([
            NotificationClient.readAllNotification(),
            NotificationClient.getNotification()
        ])
        .then( x => {
            this.notifications = x[1];
        })
    }

    onNotificationReceivedInNotificationsSection(val: string) {
        var notification: OM.NotificationVM = JSON.parse(val);
        this.notifications.unshift(notification);
        NotificationClient.readNotification(notification.identifier, false);
    }

    notificationClicked(notification: OM.NotificationVM){
        notificationClicked(notification);
    }

    beforeUnmount(){
        this.pauseCallback();
        document.removeEventListener("resume", this.resumeCallback, false);
        document.removeEventListener("pause", this.pauseCallback, false);
    }
}
